import { useState } from 'react';
import './App.css';

function App() {
  const [visible, setVisible] = useState(false)
  const judge = () => {
    let user = navigator.userAgent
    return {
      trident: user.indexOf('Trident') > -1, //IE内核  
      presto: user.indexOf('Presto') > -1, //opera内核  
      webKit: user.indexOf('AppleWebKit') > -1, //苹果、谷歌内核  
      gecko: user.indexOf('Gecko') > -1 && user.indexOf('KHTML') === -1, //火狐内核
      mobile: !!user.match(/AppleWebKit.*Mobile.*/) || !!user.match(/AppleWebKit/), //是否为移动终端
      ios: !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端  
      iPhone: user.indexOf('iPhone') > -1, //是否为iPhone终端   
      iPad: user.indexOf('iPad') > -1, //是否iPad终端    
      Windows: user.indexOf('Windows') > -1, //是否Windows终端   
      Android: user.indexOf('Android') > -1, //Android终端
      webApp: user.indexOf('Safari') === -1 //是否web应该程序，没有头部与底部
    };
  }
  // https://tempapp.scbjtznkj.com/ 安卓端下载路径
  // https://apps.apple.com/cn/app/id1617988477 ios端下载路径
  const dom = () => {
    let version = judge()
    if (version.iPhone) {
      window.location = 'https://apps.apple.com/cn/app/id1617988477'
      setTimeout(() => {
        setVisible(true)
      }, 500);
    } else {
      window.open('https://apps.apple.com/cn/app/id1617988477')
    }
  }
  const download = () => {
    let version = judge()
    if (version.Android) {
      window.location = ('finebaby-latest.apk')
    } else {
      window.open('finebaby-latest.apk')
    }
  }
  const Mask = () => {
    if (visible) {
      return (
        <div className='mask'>
          <div className='info'>
            <img src={require('./image/info.png')} alt='' onClick={() => { setVisible(false) }} />
          </div>
        </div>
      )
    }
  }
  return (
    <div className="App">
      <Mask />
      <div className='header'>
        <div className='title'>
          <span style={{ color: '#ff6c00' }}>汉尔&nbsp;</span>
          <span style={{ color: '#555555', fontSize: '35px' }}>实时监测</span>
        </div>
        <div className='sub-title'>温度报警 安全放心</div>
      </div>
      <div className='main'>
        <div className='main-center'>
          <img src={require('./image/phone.png')} alt='' />
        </div>
      </div>
      <div className="footer">
        <div className='erweima'>
          <div className='img'>
            <img src={require('./image/mini.png')} alt='' />
          </div>
          <div className='text'>长按识别二维码</div>
        </div>
        <div className='download'>
          <div className='btn' onClick={() => { dom() }}>iOS版下载</div>
          <div className='btn' onClick={() => { download() }}>安卓版下载</div>
        </div>
      </div>
    </div>
  );
}

export default App;